import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>LOGO_RAW_SINLETRA_01</title>
    <g id="af399021-902d-441d-bc6b-3840b33f0f33" data-name="Icon">
      <polyline
        points="30.67 75.23 48.59 24.09 66.33 75.23"
        fill="none"
        stroke="#FFF"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <line
        x1="45.48"
        y1="73.23"
        x2="55.49"
        y2="44.66"
        fill="none"
        stroke="#FFF"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <line
        x1="41.27"
        y1="40.14"
        x2="53.85"
        y2="40.14"
        fill="none"
        stroke="#FFF"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <line
        x1="50.48"
        y1="60.15"
        x2="60.15"
        y2="60.15"
        fill="none"
        stroke="#FFF"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </g>
    {/* <g id="a0befab1-2858-45aa-a3f0-77b2f5e6491e" data-name="Background">
        <polygon points="63.18 3 27.93 3 3 27.93 3 63.18 27.93 88.11 63.18 88.11 88.11 63.18 88.11 27.93 63.18 3" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="6"/>
      </g> */}
    <path
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M 65, 5
              L 30, 5
              L 5, 30
              L 5, 65
              L 30, 90
              L 65, 90
              L 90, 65
              L 90, 30 z"
    />
  </svg>
);

export default IconLogo;
