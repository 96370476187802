import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(4.000000, 2.000000)">
        <polyline
          points="27.67 72.23 45.59 21.09 63.33 72.23"
          fill="none"
          stroke="#FFF"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <line
          x1="43.48"
          y1="72.23"
          x2="53.49"
          y2="43.66"
          fill="none"
          stroke="#FFF"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <line
          x1="39.27"
          y1="39.14"
          x2="51.85"
          y2="39.14"
          fill="none"
          stroke="#FFF"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <line
          x1="48.48"
          y1="59.15"
          x2="58.15"
          y2="59.15"
          fill="none"
          stroke="#FFF"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 65, 5
                    L 30, 5
                    L 5, 30
                    L 5, 65
                    L 30, 90
                    L 65, 90
                    L 90, 65
                    L 90, 30 z"
      />
    </g>
  </svg>
);

export default IconLoader;
