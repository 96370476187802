import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  // const ad = (
  //   <a href="https://dashthis.com?via=whitesquare" target="_blank" rel="noopener noreferrer">
  //     <img
  //       src="https://d2gdx5nv84sdx2.cloudfront.net/uploads/8oitjpfe/marketing_asset/banner/2761/dashthis_banner_728_x_90.jpg"
  //       alt="dashthis"
  //       border="0"
  //     />
  //   </a>
  // );
  const space = <hr></hr>;
  const one = <h1>HEY THERE! I'M</h1>;
  const two = <h2 className="big-heading">Asier Alcaide</h2>;
  const three = (
    <h3 className="big-heading">
      {/* “Without ambition one starts nothing. Without work one finishes nothing.” - Ralph Waldo Emerson */}
      {/* “What is more important, what you are going to get, or what you can give?” */}
      {/* “Ask yourself: what's more crucial, receiving or contributing?” */}
      'Is life about what you accumulate or how you contribute?'
    </h3>
  );
  const four = (
    <p>
      Welcome to the digital realm of innovation and transformation.
      <br></br>I am Asier Alcaide, a passionate software engineer dedicated to the pursuit of
      creating cutting-edge solutions that enhance the quality of people's lives.
      <br></br>
      <br></br>
      Within these digital walls, you can explore my portfolio and discover how I've been involved
      in some interesting projects. From AI-ML researcher to Big Data Developer, I participate to
      create solutions that can improve life's quality.
      {/* Hello, this is a website I created to share and . I'm a Software Engineer
      currently based in Lille, France (born in Madrid, Spain). Touching multiple stuff: AI/ML
      algorithms, highly scalable Big Data services, FinTech apps, disruptive payment systems, and
      websites like this one. */}
    </p>
  );
  const five = (
    <a href={`mailto:${email}`} className="email-link">
      Get In Touch
    </a>
  );

  const items = [
    // ad,
    space,
    one,
    two,
    three,
    four,
    five,
  ];

  return (
    <StyledHeroSection>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
