import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, traceSVG: { color: "#64ffda" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Java 11',
    'Node.js',
    'Python',
    'API/REST',
    'Flutter',
    'MongoDB',
    'Spark',
    'React',
    'SQL',
  ]; // TODO: add more skills

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! I'm Asier, a Software Engineer living at this moment in Lille, France.
              Curiosity in general, history and human behavior are some of the topics I decide to
              spend my free time on.
            </p>
            <p>
              About my professional career: While I was finishing my studies of Computer Sciences
              and Business Management at{' '}
              <a href="https://www.uc3m.es/home">Universidad Carlos III de Madrid</a>{' '}
              <a href="https://www.uc3m.es/home">(UC3M)</a> and staying in 2018{' '}
              <a href="https://www.tuni.fi/en">Technological University of Tampere</a>{' '}
              <a href="https://www.tuni.fi/en">(TUT)</a> in Finland, I joined the Software
              Engineering team at <a href="https://www.masmovil.es/">MasMovil</a>, a very
              fast-growing company that has entered during the last years into the strong and
              competitive telecom industry in Spain.
            </p>
            <p>
              I've worked on a wide variety of interesting and meaningful projects on a daily basis,
              as a DevOps Intern, Big Data Engineer or Java Developer after it.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>

          <div>
            <p>
              Out of my professional time, I can be found in the mountains (Nature), learning about
              finance (Economy), agriculture, music, or informing about our future incoming
              (Geopolitics). Ah! Almost forgot! I also use to challenge myself trying to cook new
              recipes that can evoke emotions in people{' '}
              <span role="img" aria-label="smile">
                😄
              </span>
            </p>
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <Img fluid={data.avatar.childImageSharp.fluid} alt="Avatar" className="img" />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
